import { useEffect, useLayoutEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import PageApp from './Pages/PageApp';
import RxStorage from './rx/rxComponent';
import LoginPage from './Pages/PageLogin';
import AICreatePage from './Pages/AICreatePage';

import { AMPLIFY_CLIENT_ID, AMPLIFY_POOL_ID } from './Constants';
import { eventEmitter } from 'rx/rxState';
import LogInWrapper from 'Components/LogInWrapper/LogInWrapper';
import SingUpWrapper from 'Components/SingUpWrapper/SingUpWrapper';
import StripePlansPage from 'Components/stripePlansPage/StripePlansPage';
import Preview from './Components/Preview/Preview';
import BookGenerator from './Pages/BookGenerator';
import BookEditorView from './Components/books/BookEditorView';
import HtmlRender from 'Pages/HtmlRender';
import BookEditor from 'Pages/BookEditor';
import AiPageQuestions from 'Components/AiTemplate/AiPage';
import UpdatePlanPopup from 'Components/Popups/UpdatePlanPopup';
import ProtectedRoute from 'Components/ProtectedRoute/ProtectedRoute';

declare global {
  interface Document {
    cognitoUser: any;
  }
}

let awsconfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: AMPLIFY_POOL_ID,
  aws_user_pools_web_client_id: AMPLIFY_CLIENT_ID,
  oauth: {},
};
Amplify.configure(awsconfig);

interface IProps {
  editMode: string;
  productMode: string;
  productId: string;
}

interface IParams {
  user: any;
  page: string;
  productId: string;
}

const App = (props: IProps) => {
  function PageAppPreviewMode() {
    let { page, productId } = useParams<IParams>();
    return <Preview pageId={productId} />;
  }

  function PageAppEditMode() {
    let { user, page, productId } = useParams<IParams>();
    return (
      <PageApp
        editMode={true}
        page={page}
        productId={productId}
        username={user}
      />
    );
  }

  let productId: string | null = props.productId;
  let page = 'optin';
  if (props.productMode) {
    productId =
      document.getElementById('meta-funnel-id')?.getAttribute('data') || null;
    console.log('LIVE PARAMS', productId);
  }

  useLayoutEffect(() => {
    const url = window.location.href;
    const hostname = new URL(url).hostname;
    let domain;

    if (
      hostname === 'localhost' ||
      hostname === 'app.dev.autofunnel.ai' ||
      hostname === 'app.autofunnel.ai'
    ) {
      domain = 'app.autofunnel.ai';
    } else {
      domain = hostname;
    }

    document
      .getElementById('global-styles')
      ?.setAttribute(
        'href',
        `https://cdn.autofunnel.ai/autosaas/${domain}/index.css`
      );

    const linkIcon = document.querySelector('link[rel="icon"]');
    if (linkIcon) {
      linkIcon.setAttribute(
        'href',
        `https://cdn.autofunnel.ai/autosaas/${domain}/favicon?${new Date().getTime()}`
      );
    }
  }, []);

  useEffect(() => {
    eventEmitter.next({ type: 'fetch-current-user' });
  }, []);

  return (
    <>
      {props.editMode ? (
        //EDIT mode
        <Router>
          <RxStorage />
          <UpdatePlanPopup />
          <Switch>
            <Route path={`/edit/login`}>
              <LogInWrapper isModal={false} />
            </Route>
            <Route path={`/edit/signup`}>
              <SingUpWrapper versionStripePage="withInput" />
            </Route>
            <Route path={`/edit/signup2`}>
              <SingUpWrapper versionStripePage="withoutInput" />
            </Route>
            <Route path="/edit/login/:user/:productId/:page">
              <LoginPage />
            </Route>
            <ProtectedRoute
              exact
              path="/edit/generate"
              child={<Redirect to={`/console`} />}
            />
            <ProtectedRoute
              path="/edit/generate/:user/:productId/:page"
              child={<AICreatePage />}
            />
            <ProtectedRoute
              path="/edit/edit/:user/:productId/:page"
              child={<PageAppEditMode />}
            />
            <ProtectedRoute
              path={`/edit/view/:productId/:page`}
              child={<PageAppPreviewMode />}
            />
            <ProtectedRoute
              path={`/edit/pricing`}
              child={<StripePlansPage />}
            />
            <ProtectedRoute
              path={`/edit/special-discount`}
              child={<StripePlansPage discount={true} />}
            />
            <ProtectedRoute
              path={`/edit/create/funnel-template`}
              child={<PageApp editMode={true} isTemplate={true} />}
            />
            <ProtectedRoute
              path={`/edit/edit/funnel-template/:templateId`}
              child={<PageApp editMode={true} isTemplate={true} />}
            />
            <ProtectedRoute
              path={`/edit/no-trial-special`}
              child={<StripePlansPage discount={false} />}
            />
            <ProtectedRoute path={`/edit/book`} child={<BookGenerator />} />
            <ProtectedRoute path={`/edit/html`} child={<HtmlRender />} />
            <ProtectedRoute
              path={`/edit/book-editor/:bookId`}
              child={<BookEditor />}
            />
            <ProtectedRoute
              path={`/edit/ai-page/:pageId`}
              child={<AiPageQuestions />}
            />
          </Switch>
        </Router>
      ) : (
        //Live Mode
        <Router>
          {/* REGULAR PAGES */}
          <Switch>
            <Route path={`/edit/l`} exact={false}>
              <PageApp
                editMode={props.editMode}
                page={page}
                productId={productId}
                productMode={props.productMode}
              />
            </Route>
            {/* BOOK PAGES */}
            <Route path={`/edit/bl/:bookId`} exact={false}>
              <BookEditorView liveMode={true} />
            </Route>
            {/*FOR LIVE SERVER BUILD*/}
            <Route path="/*">
              <PageApp
                editMode={props.editMode}
                page={page}
                productId={productId}
                productMode={props.productMode}
              />
            </Route>
          </Switch>
          <RxStorage />
        </Router>
      )}
    </>
  );
};

export default App;
