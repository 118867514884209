import { eventEmitter, rxIsLoading, rxTemplateSaved } from '../rxState';
import { graphQlCall } from '../../graphql/utils';
import { api, getToken } from 'utils/api';
import { base64ToFile } from 'utils/base64toFile';
import { PAGECRAFT_API_URL } from 'Constants';
import QUERIES from 'graphql/queries';
import domtoimage from 'utils/dom-to-image';

export const createTemplate = async (state: any) => {
  let currentPage = state?.currentPage;
  if (!currentPage || currentPage === '') {
    currentPage = 'optin';
  }

  try {
    rxIsLoading.next('Loading...');
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_TEMPLATE,
      headerType: 'USER-AUTH',
      values: {
        pages: JSON.stringify([
          {
            data: state?.blocks,
            name: currentPage,
            exitpopupdata: state?.exitModalData,
            exitpopupenabled: state?.exitModalEnabled,
            image: '',
          },
        ]),
        image: '',
        name: state.templateData.name,
        description: state.templateData.description,
        category: state.templateData.category,
      },
    });
    console.log('response', response);

    const domNode = document.getElementById('view');
    domtoimage.toPng(domNode as HTMLElement, {}).then(function (dataUrl) {
      saveTemplateImage(response._id, dataUrl, response.name).then(() => {
        eventEmitter.next({
          type: 'add-alert',
          payload: {
            type: 'success',
            text: 'Save successfully',
          },
        });
        rxTemplateSaved.next(true);
      });
    });
  } catch (error: any) {
    eventEmitter.next({
      type: 'add-alert',
      payload: {
        type: 'error',
        text: 'Error during Save',
        description: error.message,
      },
    });
  } finally {
    rxIsLoading.next('');
  }
};

export const updateTemplate = async (state: any) => {
  let currentPage = state.currentPage;
  if (!currentPage || currentPage === '') {
    currentPage = 'optin';
  }

  try {
    rxIsLoading.next('Loading...');
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_TEMPLATE,
      headerType: 'USER-AUTH',
      values: {
        id: state.templateId,
        pages: JSON.stringify([
          {
            data: state.blocks,
            name: currentPage,
            exitpopupdata: state.exitModalData,
            exitpopupenabled: state.exitModalEnabled,
          },
        ]),
        name: state.templateData.name,
        description: state.templateData.description,
        category: state.templateData.category,
      },
    });

    const domNode = document.getElementById('view');
    domtoimage.toPng(domNode as HTMLElement, {}).then(function (dataUrl) {
      saveTemplateImage(response._id, dataUrl, response.name);
    });

    eventEmitter.next({
      type: 'add-alert',
      payload: {
        type: 'success',
        text: 'Save successfully',
      },
    });
    rxTemplateSaved.next(true);
  } catch (error: any) {
    eventEmitter.next({
      type: 'add-alert',
      payload: {
        type: 'error',
        text: 'Error during Save',
        description: error.message,
      },
    });
  } finally {
    rxIsLoading.next('');
  }
};

export const saveTemplateImage = async (
  templateId: string,
  image: string,
  templateName: string
) => {
  const file = base64ToFile(image, templateName);
  try {
    const data = new FormData();
    data.append('id', templateId);
    data.append('file', file);
    const response = await api(
      `${PAGECRAFT_API_URL}/template/upload/image`,
      'PATCH',
      data,
      {
        authorization: getToken(),
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
